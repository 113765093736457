<template>
  <transition name="dialog-fade">
    <div class="contrast-dialog" v-show="contrastVisible">
      <div class="continer">
        <div class="continer-header">
          <p>对比设备[已选{{ contrastList.length }}个设备]</p>
          <i class="el-icon-close" @click="destroy"></i>
        </div>
        <div class="continer-body">
          <ul class="contrast-list" v-if="contrastList.length">
            <li
              class="contrast-list-item"
              v-for="item in contrastList"
              :key="item.deviceId"
            >
              <div class="title">
                <div class="icon-box">
                  <i
                    :class="['iconfont', iconMap[Number(item.firstCategory)]]"
                  ></i>
                </div>
                <span class="title-detail">{{
                  item.deviceTypeName || "--"
                }}</span>
              </div>
              <div class="type">
                <base-icon icon="kehubianhao"></base-icon>
                <span>{{ item.deviceNo || "--" }}</span>
              </div>
              <i
                class="iconfont icon-shanchu delete"
                @click="handleDelete(item)"
              ></i>
            </li>
          </ul>
          <gc-empty v-else />
        </div>
        <el-button
          class="continer-footer"
          :disabled="disabled"
          @click="getToContrast"
        >
          去对比分析
        </el-button>
      </div>
    </div>
  </transition>
</template>
<script>
import BaseIcon from "@/components/BaseIcon";
export default {
  name: "contrast-dialog",
  components: {
    BaseIcon,
  },
  props: {
    contrastNumber: {
      type: [Number, String],
      default: 0,
    },
    contrastVisible: {
      type: Boolean,
      default: false,
    },
    contrastData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    contrastData: {
      handler(val) {
        this.contrastList = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      disabled: false,
      iconMap: {
        311: "icon-zonghejiankong",
        401: "icon-jiliangyi",
        409: "icon-yalicaiji",
        909: "icon-baojingqi",
        9999: "icon-zonghejiankong",
      },
      contrastList: [],
    };
  },
  methods: {
    destroy() {
      this.$emit("update:contrastVisible", false);
    },
    handleDelete(row) {
      this.contrastList = this.contrastList.filter((item) => {
        return item.deviceId !== row.deviceId;
      });
      this.$emit("deleteContrast", row, this.contrastList);
    },
    getToContrast() {
      if (!this.contrastList.length)
        return this.$message.warning("请选择对比数据");
      this.disabled = true;
      const deviceIds = this.contrastList.map((i) => i.deviceId).join(",");
      this.$router.push({
        name: "contrastAnalysis",
        query: {
          deviceIds,
        },
      });
      this.disabled = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.contrast-dialog {
  height: calc(100vh - 105px);
  min-height: 280px;
  width: 416px;
  background: #e7e8f2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: auto;
  z-index: 9;
  .continer {
    &-header,
    &-footer {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      font-family: "Source Han Sans CN";
    }
    &-header {
      height: 42px;
      line-height: 42px;
      background: #667fff;
      opacity: 0.95;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      border-radius: 8px 8px 0 0;
      i {
        line-height: 42px;
      }
    }
    &-footer {
      width: 392px;
      height: 40px;
      position: absolute;
      left: 12px;
      bottom: 20px;
      background: #667fff;
      border-radius: 8px;
      &:active {
        background-color: #99a7f0;
      }
    }
    &-body {
      .contrast-list {
        height: calc(100% - 102px);
        padding: 0 12px;
        &-item {
          position: relative;
          height: 68px;
          padding: 10px 0 0 10px;
          background: #ffffff;
          border-radius: 3px;
          margin-top: 12px;
          .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            .icon-box {
              display: inline-block;
              width: 24px;
              height: 24px;
              text-align: center;
              line-height: 24px;
              background: #d7dbf2;
              color: #4d6bff;
              border-radius: 4px;
              margin-right: 8px;
              i {
                font-size: 22px;
              }
            }
          }
          .type {
            margin-top: 7px;
            margin-left: 32px;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #5c6073;
            span {
              margin-left: 5px;
            }
          }
          .delete {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 26px;
            color: #e5662e;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
// 进入动画
.dialog-fade-enter-active {
  animation: dialog-fade-in 0.4s;
}
// 离开动画
.dialog-fade-leave-active {
  animation: dialog-fade-out 0.4s;
}

@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
</style>
